import React from 'react';
import {Page} from '../../types';
import {Link} from '../../components';

type MenuItem = {
  title: string;
  link: Page;
};

export const DropdownMenu = ({menuItems}: {menuItems: MenuItem[]}) => {
  return (
    <div className="dropdown-menu">
      <ul>
        {menuItems.map((item, i) => {
          return (
            <li key={i} className="text-smallbodymob">
              <Link className="hover:underline" linkTo={item.link}>
                {item.title}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
