import React, {useEffect, useState, useCallback, useMemo, useRef} from 'react';

import {Logo} from '../../components';
import {Link} from '../../components/common';
import {Page} from '../../types';
import {isBrowser} from '../../utils/isBrowser';
import {useStore} from '../../store';

import {
  NavDownloadCTA,
  NavDownloadCTAMob,
  BurgerMenu,
  BurgerPanel,
} from './index';
import {Banner} from './Banner';
import {DropdownMenu} from './DropDownMenu';

const lightTextPages: Page[] = ['home', 'teens'];

const yellowBackgroundPages: Page[] = ['schools', 'newsletter-sign-up'];

interface INavHeader {
  activePage: Page;
  showBanner: boolean;
  bannerItems: {text: string; link: string}[];
  pageContext: 'parent' | 'generic';
}

export function NavHeader({
  activePage,
  showBanner,
  bannerItems,
  pageContext,
}: INavHeader) {
  const logoDisplayMode = lightTextPages.includes(activePage)
    ? 'light'
    : 'dark';

  const navBarButton = useMemo(() => {
    let ctaCopy = 'Download';
    let linkTo: Page = 'download';
    if (pageContext === 'parent') {
      ctaCopy = 'Subscribe';
      linkTo = 'premium';
    }
    return {
      ctaCopy,
      linkTo,
    };
  }, [pageContext]);

  const buttonDisplayMode = yellowBackgroundPages.includes(activePage)
    ? 'bg-primaryred ml-6 justify-center hover:bg-[#EBD384]'
    : undefined;
  const textColor =
    logoDisplayMode === 'dark' ? 'text-black' : 'text-background';

  const {sideMenuOpen, onSetSideMenuOpen} = useStore();
  const [scrollPosition, setScrollPosition] = useState(
    isBrowser ? window.scrollY : 0,
  );
  const scrollPositionRef = useRef(scrollPosition);
  const [styling, setStyling] = useState('');

  const pastTop = useMemo(() => {
    return scrollPosition > 50;
  }, [scrollPosition]);

  const onHandleScroll = useCallback(() => {
    setScrollPosition(window.scrollY);
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener('scroll', onHandleScroll);
      return () => {
        window.removeEventListener('scroll', onHandleScroll);
      };
    }
  }, []);

  useEffect(() => {
    // within top 50px show the standard nav bar
    // when scrolling down beyond 50px fade out the nav bar
    // when scrolling up beyond 50px show the white BG nav bar
    if (scrollPosition !== scrollPositionRef.current) {
      if (scrollPosition > scrollPositionRef.current) {
        if (scrollPosition > 50) {
          setStyling('animate-fadeOut');
        }
      } else {
        if (scrollPosition <= 50) {
          setStyling('animate-fadeBackground');
        } else {
          setStyling('animate-fadeIn bg-background bg-opacity-100');
        }
      }
    }
    scrollPositionRef.current = scrollPosition;
  }, [scrollPosition]);

  const [isParentDropdownVisible, setParentDropdownVisible] = useState(false);

  const handleMouseEnter = () => {
    setParentDropdownVisible(true);
  };

  const handleMouseLeave = () => {
    setParentDropdownVisible(false);
  };

  return (
    <div className={`fixed z-50 top-0 left-0 right-0 bg-none ${styling}`}>
      {showBanner && <Banner bannerItems={bannerItems} />}
      <div className="flex justify-center mx-8 lg:mx-28">
        <div className="py-3 w-full max-w-screen-xl">
          <div className="flex  justify-between">
            <div className="w-1/3 md:hidden">
              <BurgerMenu
                onClick={() => {
                  onSetSideMenuOpen(true);
                }}
                mode={pastTop ? 'dark' : logoDisplayMode}
              />
            </div>
            <div className="relative mx-auto lg:mx-0 w-1/3 md:w-auto">
              <Link linkTo="home">
                <Logo mode={pastTop ? 'dark' : logoDisplayMode} />
              </Link>
            </div>
            <div className="hidden md:flex">
              <div
                className={`${pastTop ? 'dark' : textColor}  hidden md:flex pt-2`}>
                <Link className="px-2 lg:px-3 hover:underline" linkTo="premium">
                  Buy luna now
                </Link>
                <Link
                  className="px-2 lg:px-3 hover:underline"
                  linkTo="about-us">
                  About us
                </Link>
                <Link className="px-2 lg:px-3 hover:underline" linkTo="teens">
                  I&apos;m a teen
                </Link>
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="relative px-2 lg:px-3 flex-col align-top">
                  <Link className="hover:underline" linkTo="parents">
                    I&apos;m a parent
                  </Link>
                  {isParentDropdownVisible && (
                    <div className="pt-2">
                      <DropdownMenu
                        menuItems={[
                          {title: 'Learn more', link: 'parents'},
                          {title: 'Guides', link: 'parents/guides'},
                          {title: 'Newsletter', link: 'newsletter-sign-up'},
                        ]}
                      />
                    </div>
                  )}
                </div>
                {/* <Link className="px-2 lg:px-3 hover:underline" linkTo="work-with-us">
                work with us
              </Link> */}
                <Link className="px-2 lg:px-3 hover:underline" linkTo="schools">
                  Schools
                </Link>
              </div>
              {activePage !== 'download' && (
                <div>
                  <NavDownloadCTA className={buttonDisplayMode} />
                </div>
              )}
            </div>
            {/* MOBILE */}
            {activePage !== 'download' ? (
              <NavDownloadCTAMob
                color={
                  yellowBackgroundPages.includes(activePage)
                    ? 'bg-primaryred'
                    : undefined
                }
                ctaCopy={navBarButton.ctaCopy}
                linkTo={navBarButton.linkTo}
              />
            ) : (
              <div className="w-[50px] lg:hidden"></div>
            )}
          </div>
        </div>
      </div>
      <BurgerPanel
        open={sideMenuOpen}
        onClose={() => {
          onSetSideMenuOpen(false);
        }}
      />
    </div>
  );
}
