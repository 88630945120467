exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-applink-tsx": () => import("./../../../src/pages/applink.tsx" /* webpackChunkName: "component---src-pages-applink-tsx" */),
  "component---src-pages-applinkdev-tsx": () => import("./../../../src/pages/applinkdev.tsx" /* webpackChunkName: "component---src-pages-applinkdev-tsx" */),
  "component---src-pages-applinkstaging-tsx": () => import("./../../../src/pages/applinkstaging.tsx" /* webpackChunkName: "component---src-pages-applinkstaging-tsx" */),
  "component---src-pages-articles-index-tsx": () => import("./../../../src/pages/articles/index.tsx" /* webpackChunkName: "component---src-pages-articles-index-tsx" */),
  "component---src-pages-download-tsx": () => import("./../../../src/pages/download.tsx" /* webpackChunkName: "component---src-pages-download-tsx" */),
  "component---src-pages-gen-alpha-reports-tsx": () => import("./../../../src/pages/gen-alpha-reports.tsx" /* webpackChunkName: "component---src-pages-gen-alpha-reports-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-sign-up-tsx": () => import("./../../../src/pages/newsletter-sign-up.tsx" /* webpackChunkName: "component---src-pages-newsletter-sign-up-tsx" */),
  "component---src-pages-parents-guides-tsx": () => import("./../../../src/pages/parents/guides.tsx" /* webpackChunkName: "component---src-pages-parents-guides-tsx" */),
  "component---src-pages-parents-tsx": () => import("./../../../src/pages/parents.tsx" /* webpackChunkName: "component---src-pages-parents-tsx" */),
  "component---src-pages-premium-index-tsx": () => import("./../../../src/pages/premium/index.tsx" /* webpackChunkName: "component---src-pages-premium-index-tsx" */),
  "component---src-pages-premium-purchase-successful-tsx": () => import("./../../../src/pages/premium/purchase-successful.tsx" /* webpackChunkName: "component---src-pages-premium-purchase-successful-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-questions-index-tsx": () => import("./../../../src/pages/questions/index.tsx" /* webpackChunkName: "component---src-pages-questions-index-tsx" */),
  "component---src-pages-schools-tsx": () => import("./../../../src/pages/schools.tsx" /* webpackChunkName: "component---src-pages-schools-tsx" */),
  "component---src-pages-shop-tsx": () => import("./../../../src/pages/shop.tsx" /* webpackChunkName: "component---src-pages-shop-tsx" */),
  "component---src-pages-teens-tsx": () => import("./../../../src/pages/teens.tsx" /* webpackChunkName: "component---src-pages-teens-tsx" */),
  "component---src-pages-terms-conditions-tsx": () => import("./../../../src/pages/terms-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-conditions-tsx" */),
  "component---src-pages-usa-waitlist-parents-tsx": () => import("./../../../src/pages/usa-waitlist-parents.tsx" /* webpackChunkName: "component---src-pages-usa-waitlist-parents-tsx" */),
  "component---src-pages-usa-waitlist-tsx": () => import("./../../../src/pages/usa-waitlist.tsx" /* webpackChunkName: "component---src-pages-usa-waitlist-tsx" */),
  "component---src-pages-work-with-us-tsx": () => import("./../../../src/pages/work-with-us.tsx" /* webpackChunkName: "component---src-pages-work-with-us-tsx" */),
  "component---src-templates-article-tsx": () => import("./../../../src/templates/article.tsx" /* webpackChunkName: "component---src-templates-article-tsx" */),
  "component---src-templates-articles-category-tsx": () => import("./../../../src/templates/articles-category.tsx" /* webpackChunkName: "component---src-templates-articles-category-tsx" */),
  "component---src-templates-parent-articles-category-tsx": () => import("./../../../src/templates/parent-articles-category.tsx" /* webpackChunkName: "component---src-templates-parent-articles-category-tsx" */),
  "component---src-templates-parents-article-tsx": () => import("./../../../src/templates/parents-article.tsx" /* webpackChunkName: "component---src-templates-parents-article-tsx" */),
  "component---src-templates-question-tsx": () => import("./../../../src/templates/question.tsx" /* webpackChunkName: "component---src-templates-question-tsx" */),
  "component---src-templates-questions-category-tsx": () => import("./../../../src/templates/questions-category.tsx" /* webpackChunkName: "component---src-templates-questions-category-tsx" */)
}

